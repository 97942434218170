import React from 'react'
import Layout from '../../components/Layout'
import H1 from '../../components/H1'

export default function Page() {
  return (
    <Layout>
      <H1 title="Jobs" />
      {/* <p>
        No jobs listed at this time.
      </p> */}
        <ul>
        <li>
          <a target="_blank" href="https://www.talladega.edu/careers/assistant-director-of-institutional-effectiveness-and-researchquality-enhancement-plan-qep-director/">
          Talladega College - Assistant Director of Institutional Effectiveness and Research/Quality Enhancement Plan (QEP) Director
          </a>
        </li>
        {/*
        <li>
          <a target="_blank" href="https://uab.taleo.net/careersection/ext/jobdetail.ftl?job=T199197&tz=GMT-05%3A00&tzname=America%2FChicago">
          University of Alabama in Birmingham - Project Manager
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.schooljobs.com/careers/accs/northwest/jobs/3659221/director-of-institutional-research-administrative-systems-analyst?pagetype=jobOpportunitiesJobs">
          Northwest-Shoals Community College - Director of Institutional Research/Administrative Systems Analyst
          </a>
        </li>
        <li>
          <a target="_blank" href="https://ulsuno.wd1.myworkdayjobs.com/en-US/UniversityOfNewOrleans/job/New-Orleans-La/Graduate-Admissions-Counselor_R-000601">
          THE UNIVERSITY OF NEW ORLEANS - Graduate Admissions Counselor
          </a>
        </li>
        <li>
          <a target="_blank" href="/Assistant Director of Organizational Research & Effectiveness.pdf">
          ALABAMA COMMUNITY COLLEGE SYSTEM - Assistant Director of Organizational Research & Effectiveness
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.uwa.edu/about/universitydepartments/employment/PandA">
          THE UNIVERSITY OF WEST ALABAMA - Coordinator of Planning and Assessment
          </a>
        </li>
        <li>
          <a target="_blank" href="/Coordinator of Planning and Assessment.pdf">
          THE UNIVERSITY OF WEST ALABAMA - Coordinator of Planning and Assessment
          </a>
        </li>
        <li>
          <a target="_blank" href="/Retention Specialist_Advisor.pdf">
          THE UNIVERSITY OF WEST ALABAMA - Retention Specialist Advisor
          </a>
        </li>
        <li>
          <a target="_blank" href="/VacancyAnnouncement_InstuitionalResearchSpecialist.pdf">
          COASTAL ALABAMA COMMUNITY COLLEGE VACANCY ANNOUNCEMENT - Institutional Research Specialist
          </a>
        </li>
        <li>
          <a target="_blank" href="https://careers.uah.edu/cw/en-us/job/494003/senior-academic-success-data-analyst">
          University of Alabama in Huntsville - Senior Academic Success Data Analyst
          </a>
        </li>
        <li>
          <a target="_blank" href="/researchassociate.pdf">
            Research Associate - Alabama Commission on Higher Education
          </a>
        </li>
        <li>
          <a target="_blank" href="https://uab.taleo.net/careersection/ext/jobdetail.ftl?job=T180169&tz=GMT-06%3A00&tzname=America%2FChicago">
            INSTITUTIONAL RESEARCH ANALYST II (T180169) - UAB
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.jeffersonstate.edu/wp-content/uploads/2021/01/Instructional-Design-Specialist-7-20.pdf">
            INTENT TO EMPLOY Reposting Date: January 29, 2021 Instructional Design Specialist
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.jeffersonstate.edu/wp-content/uploads/2021/01/Dean-of-Instruction-1-21.pdf">
            INTENT TO EMPLOY Posting Date: January 28, 2021
          </a>
        </li>
          <li>
            <a target="_blank" href="http://jobs.uasystem.edu/systemsoffice/en-us/job/510771/510771-assistantassociate-vice-chancellor-for-system-analytics-and-business-intelligence">
              Assistant/Associate Vice Chancellor for System Analytics and Business Intelligence (#510771) - closing 03.13.20
            </a>
          </li>
          <li>
            <a target="_blank" href="http://staffjobs.ua.edu/cw/en-us/job/510466/institutional-research-analyst-senior-510466">
              Institutional Research Analyst Sr. (#510466) - closing 02.20.20
            </a>
          </li>
          <li>
            <a target="_blank" href="http://staffjobs.ua.edu/cw/en-us/job/510465/institutional-research-analyst-510465">
              Institutional Research Analyst (#510465) - closing 02.20.20
            </a>
          </li>
          <li>
            <a target="_blank" href="/UA Web Resources Integration Analyst_closing 02.29.20.pdf">
              Web Resources Integration Analyst (#509132) - closing 02.29.20
            </a>
          </li>*/}
        </ul>
    </Layout>
  )
}
